import { makeObservable, observable, action, runInAction, toJS } from 'mobx';
import {
  FilterCategory,
  PaginationConfig,
  QuickFilterItem,
  SelectedFilterIds,
  TableColumn,
  TableColumnConfigurable,
  defaultPaginationConfig,
  generatePages,
  toggleColumns,
} from '../modals/table.modal';
import { QueryParamDTO } from '../../shared/dto/table.dto';

export abstract class ListBaseStore<T> {
  isLoading: boolean = false;
  isInitialLoading: boolean = true;
  isEmptyList: boolean = true;
  isPagination: boolean = true;
  items: T[] = [];
  originalResponse: any;
  sortOrder: string = 'asc';
  searchQuery: string = '';

  mainId: number | undefined;
  abstract sortField: string;
  abstract columns: TableColumnConfigurable[];
  abstract quickFilters: QuickFilterItem[];
  abstract filterCategories: FilterCategory[];
  extraParams: QueryParamDTO[] = []; //@todo late to be make extra param as abstract
  paginationConfig: PaginationConfig = defaultPaginationConfig;

  constructor() {
    makeObservable(this, {
      items: observable,
      sortOrder: observable,
      searchQuery: observable,
      paginationConfig: observable,
      isLoading: observable,
      isInitialLoading: observable,
      isEmptyList: observable,
      setSort: action,
      setSearchQuery: action,
      setPage: action,
      loadItems: action,
      resetEntireState: action,
      toggleColumn: action,
      getEnabledColumns: observable,
    });
  }

  initialize(): void {} //@todo will make abstract later
  abstract get service(): any;
  protected mainConv: any; //@todo will make abstract later
  abstract resetEntireState(): void;
  abstract getMasterLists(): void;
  triggerItemsLoaded() {}

  //@todo will make abstract later
  convertResponseToListDTO(response: any): T[] {
    if (this.mainConv) {
      //@todo to be removed and mainconv will be mandatory mandatory for sub class
      return this.mainConv.resToList(response);
    }
    return response;
  }

  setSort(event: { field: string; order: string }): void {
    this.sortField = event.field;
    this.sortOrder = event.order;
    this.loadItems();
  }

  setSearchQuery(query: string): void {
    this.searchQuery = query;
    this.loadItems();
  }

  setPage(page: number): void {
    this.paginationConfig.currentPage = page;
    this.loadItems();
  }

  loadItems(): void {
    this.isLoading = true;
    const activeFilter = this.quickFilters.find((filter) => filter.isActive);
    const activeFilterKey = activeFilter ? activeFilter.key : undefined;
    const dynamicFilters = this.getAllSelectedIds();

    let listCall;

    if (this.mainId) {
      listCall = this.service.list(
        this.mainId,
        this.sortField,
        this.sortOrder,
        this.paginationConfig.currentPage,
        this.paginationConfig.pageSize,
        this.searchQuery,
        activeFilterKey,
        dynamicFilters,
        this.extraParams
      );
    } else {
      listCall = this.service.list(
        this.sortField,
        this.sortOrder,
        this.paginationConfig.currentPage,
        this.paginationConfig.pageSize,
        this.searchQuery,
        activeFilterKey,
        dynamicFilters,
        this.extraParams
      );
    }

    listCall.subscribe({
      next: (response: any) => {
        runInAction(() => {
          this.originalResponse = response;
          //with pagination
          if (this.isPagination) {
            this.items = this.convertResponseToListDTO(response.items);
            this.paginationConfig.totalPages = response.pagination.total_pages;
            this.paginationConfig.totalCount = response.pagination.total_count;
            this.paginationConfig.pages = generatePages(
              this.paginationConfig.currentPage,
              this.paginationConfig.totalPages
            );
            this.isEmptyList = this.paginationConfig.totalCount <= 0;
          } else {
            //without pagination
            if (this.isInitialLoading) {
              if (response.length > 0) {
                this.isEmptyList = false;
              }
            }
            this.items = this.convertResponseToListDTO(response);
          }
          this.isInitialLoading = false;
          this.isLoading = false;
          this.triggerItemsLoaded();
        });
      },
      error: (error: any) => {
        console.error('Failed to load data:', error);
        this.isLoading = false;
      },
    });
  }

  toggleColumn(field: string): void {
    toggleColumns(this.columns, field);
  }

  getEnabledColumns(): TableColumn[] {
    return this.columns.filter((column) => column.isEnable);
  }

  getAllSelectedIds(): SelectedFilterIds {
    const selectedIds: SelectedFilterIds = {};

    this.filterCategories.forEach((category) => {
      selectedIds[category.key] = category.options
        .filter((option) => option.isSelected)
        .map((option) => parseInt(option.id));
    });

    return selectedIds;
  }
}
